import $ from 'jquery'

let trigger=trigger||{};
trigger={
    slideDown: $('[data-trigger-slideDown]')
};

let target=target||{};
target={
    slideDown: $('[data-target-slideDown]')
};

export default function () {
    if (!trigger.slideDown.length) return;
    trigger.slideDown.on('click',function () {
        if (!$(this).hasClass('is-active')) {
            $(this).addClass('is-active');
            $(this).prev('.campaignPage__box__list__itemInner').slideToggle().addClass('is-active');
            $(this).text('閉じる');
        } else {
            $(this).removeClass('is-active');
            $(this).prev('.campaignPage__box__list__itemInner').slideToggle().removeClass('is-active');
            $(this).text('詳しくはこちら');
        }
    });
    // アンカー取得
    const anchor=$(location).attr('hash');
    if (anchor) {
        let point=$(anchor).offset().top;
        let position=point-150;
        $(anchor).find('.campaignPage__box__list__itemInner').addClass('is-active');
        $(anchor).find('.btn').addClass('is-active');
        $(anchor).find('.btn').text('閉じる');
        $('html,body').animate({
            scrollTop: position
        },400);
    }
}