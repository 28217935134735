import 'babel-polyfill'
import $ from 'jquery'
import footer from './util/footer'
import { validate } from 'jquery-validation'
import active from './util/active'
import accordion from './util/accordion'
import datePicker from './util/datePicker'
import smoothScroll from './util/smoothScroll'
import tab from './util/tab'
import tabPrice from './util/tabPrice'
import campaign from './util/campaign'
import { formValid,formReset,enterDisallow,errorJump,submitCheck } from './util/formValid'
import moment from 'moment'
import { slide } from './util/slide'

require('moment/locale/ja');

$(() => {
    active();
    accordion();
    campaign();
    formValid();
    formReset();
    enterDisallow();
    errorJump();
    smoothScroll();
    submitCheck();
    tab();
    tabPrice();
    footer();
});

window.onload=function () {
    writeInput()
    writeInputCallentry()
}

$('#inputs').submit(function () {
    var tmpArray=$(this).serializeArray();
    var inputTime=tmpArray.find(function (input) {
        return input.name==='time';
    });

    if (!inputTime) {
        //時間が日存在時に適当な値を入れておいてあげる
        tmpArray.push({
            'name': 'time',
            'value': ''
        });
    }

    sessionStorage.setItem('form',JSON.stringify(tmpArray));
    return true;
});

$('#callentry').submit(function () {
    var tmpArray=$(this).serializeArray();
    var inputTime=tmpArray.find(function (input) {
        return input.name==='time';
    });

    if (!inputTime) {
        //時間が日存在時に適当な値を入れておいてあげる
        tmpArray.push({
            'name': 'time',
            'value': ''
        });
    }

    sessionStorage.setItem('callentry',JSON.stringify(tmpArray));
    return true;
});

$('#areacheck').submit(function () {
    var tmpArray=$(this).serializeArray();
    var inputTime=tmpArray.find(function (input) {
        return input.name==='time';
    });

    if (!inputTime) {
        //時間が日存在時に適当な値を入れておいてあげる
        tmpArray.push({
            'name': 'time',
            'value': ''
        });
    }

    sessionStorage.setItem('areacheck',JSON.stringify(tmpArray));
    areacheckSubmit();
    return false;
  });
  
  function contactData(sendData, key) {
    const url='https://prod.cd.012grp.tech/api/v2/entry'
    const unique_id = Math.random().toString(32).substring(2)
    
    
    $.ajax({
      url: url,
      async: false,
      type: "POST",
      dataType: "json",
      data: sendData,
      beforeSend: function(request){
        request.setRequestHeader("x-api-key", key);
      },
    })
    .done((data) => {
      console.log('成功')
    })
    .fail((data) => {
      console.log('失敗')
      throw new Error('contactData接続失敗')
    })
  }
  
  var entryFlag=false
  $('#submitOK').click(function () {
    if (entryFlag) return
    const inputs=sessionStorage.getItem('form')
    sessionStorage.clear()
    entryFlag=true
    
    // ここからコンタクトデータの処理---開始--------------------------------
    // 仕様書URL:https://docs.google.com/spreadsheets/d/1ncqhJaXQep0Why6yCcIQCXK5ZXlxIbRT5YMgXYfKlhk/edit#gid=0
    
    // お申し込み
    const unique_id = Math.random().toString(32).substring(2)
    const sendData={
      'phone': $('#inputTel').text(),
      'subject': "お申し込み【ポイントSBAirWEB】",
      'click_id': unique_id,
      'name': `${$('#inputFirstName').text()} ${$('#inputLastName').text()}` ,
      'name_kana': `${$('#inputFistKana').text()} ${$('#inputLastKana').text()}`,
      'mail': $('#inputEmail').text(),
      'zip': $('#inputZip').text(),
      'address': $('#inputPref').text(),
      'address_2': `${$('#inputAddr01').text()} ${$('#inputAddr02').text()} ${$('#inputAddr03').text()}`,
      'question': $('#inputRemark').text(),
      'others': JSON.stringify({"お引っ越し予定日": $('#inputMovingday').text()})
    };
    
    try {
      contactData(sendData, 'zLqP4ZFFaKswrDDVTul5xBiio')
      
    } catch (e) {
      window.alert(`接続エラーが発生しました。${e}`)
      return
    }
    
    //--------------------------------------------------------------------
    // 以上でコンタクトデータの処理は終了です。
    //--------------------------------------------------------------------
    
    
    $.ajax({
      url: '../../mail.php',
      type: 'POST',
      dataTypes: 'json',
      data: JSON.parse(inputs)
      
    }).done(function (res) {
      if (res) {
        sessionStorage.clear()
        sessionStorage.setItem('code',res)
        location.href='../thanks'
      }
    })
  })
  
  var callEntryFlag=false
  $('#submitCallentry').click(function () {
    if (callEntryFlag) return
    var callentry=sessionStorage.getItem('callentry')
    sessionStorage.clear()
    callEntryFlag=true
    
    
    // ここからコンタクトデータの処理---開始--------------------------------
    // 仕様書URL:https://docs.google.com/spreadsheets/d/1ncqhJaXQep0Why6yCcIQCXK5ZXlxIbRT5YMgXYfKlhk/edit#gid=0
    
    // 電話予約
    const unique_id = Math.random().toString(32).substring(2)
    const sendData={
      'phone': $('#inputTel').text(),
      'subject': "電話予約【ポイントSBAirWEB】",
      'click_id': unique_id,
      'name': `${$('#inputFirstName').text()} ${$('#inputLastName').text()}`,
      'name_kana': `${$('#inputFistKana').text()} ${$('#inputLastKana').text()}`,
      'move_day': $('#inputMovingday').text(),
      'question': $('#inputRemark').text(),
      'others': JSON.stringify({'繋がりやすい時間帯': $('#inputTimezone').text()})
    };
    
    try {
      contactData(sendData, '7h3cOdkvp6wKPBNGSrk1gKHVU')
      
    } catch (e) {
      window.alert(`接続エラーが発生しました。${e}`)
      return
    }
    
    //--------------------------------------------------------------------
    // 以上でコンタクトデータの処理は終了です。
    //--------------------------------------------------------------------
    
    $.ajax({
      url: '../../callentryForm/mail.php',
      type: 'POST',
      dataTypes: 'json',
      data: JSON.parse(callentry)
      
    }).done(function (res) {
      if (res) {
        sessionStorage.clear()
        sessionStorage.setItem('code',res)
        location.href='../thanks'
      }
    })
  })
  // var areaCheckFlag=false
  function areacheckSubmit() {
    if (!$('#submitAreacheck').hasClass('is-disabled')) {
      var areacheck=sessionStorage.getItem('areacheck')
      sessionStorage.clear()
      // areaCheckFlag=true


      // ここからコンタクトデータの処理---開始--------------------------------
      // 仕様書URL:https://docs.google.com/spreadsheets/d/1ncqhJaXQep0Why6yCcIQCXK5ZXlxIbRT5YMgXYfKlhk/edit#gid=0

      const unique_id = Math.random().toString(32).substring(2)
      const sendData={
        'phone': $('[name="tel"]').val(),
        'subject': "エリアチェック【ポイントSBAirWEB】",
        'click_id': unique_id,
        'name_kana': `${$('[name="firstkana"]').val()} ${$('[name="lastkana"]').val()}`,
        'zip': $('[name="zip"]').val(),
        'address': $('[name="pref"]').val(),
        'address_2': `${$('[name="addr01"]').val()} ${$('[name="addr02"]').val()} ${$('[name="addr03"]').val()}`,
    　　};

        try {
            contactData(sendData, 'cLZBv3yzShd5wgyZFAnWXusSi')
            
        } catch (e) {
            window.alert(`接続エラーが発生しました。${e}`)
            return
        }
        
        $.ajax({
            url: '../../areacheckForm/mail.php',
            type: 'POST',
            dataTypes: 'json',
            data: JSON.parse(areacheck)

        }).done(function (res) {
            if (res) {
                sessionStorage.clear()
                sessionStorage.setItem('code',res)
                location.href='../areacheck/thanks'
            }
        })
    }
    return false;
}

$('#back').click(function () {
    history.back()
})

function writeInput() {
    var inputs=JSON.parse(sessionStorage.getItem('form'||"null"))

    dispConfirm(inputs,'firstname','inputFirstName')
    dispConfirm(inputs,'lastname','inputLastName')
    dispConfirm(inputs,'firstkana','inputFistKana')
    dispConfirm(inputs,'lastkana','inputLastKana')
    dispConfirm(inputs,'tel','inputTel')
    dispConfirm(inputs,'mail','inputEmail')
    dispConfirm(inputs,'zip','inputZip')
    dispConfirm(inputs,'pref','inputPref')
    dispConfirm(inputs,'addr01','inputAddr01')
    dispConfirm(inputs,'addr02','inputAddr02')
    dispConfirm(inputs,'addr03','inputAddr03')
    dispConfirm(inputs,'movingday','inputMovingday')
    dispConfirm(inputs,'note','inputRemark')
}

function dispConfirm(inputs,name,id) {
    if (!inputs) return
    if ($('#inputEmail').length) {
        var tmp=inputs.find(function (input) {
            return input.name===name;
        });
        if (tmp.value!=="0") {
            $('#'+id).text(tmp.value)
        } else {
            $('#'+id).text('')
        }
    }
}

function writeInputCallentry() {
    var callentry=JSON.parse(sessionStorage.getItem('callentry'||"null"))

    dispConfirmCallentry(callentry,'firstname','inputFirstName')
    dispConfirmCallentry(callentry,'lastname','inputLastName')
    dispConfirmCallentry(callentry,'firstkana','inputFistKana')
    dispConfirmCallentry(callentry,'lastkana','inputLastKana')
    dispConfirmCallentry(callentry,'tel','inputTel')
    dispConfirmCallentry(callentry,'timezone','inputTimezone')
    dispConfirmCallentry(callentry,'note','inputRemark')
}

function dispConfirmCallentry(callentry,name,id) {
    if (!callentry) return
    if ($('#inputFirstName').length) {
        var tmp=callentry.find(function (input) {
            return input.name===name;
        });
        if (tmp.value!=="0") {
            $('#'+id).text(tmp.value)
        } else {
            $('#'+id).text('')
        }
    }
}