import $ from 'jquery'

export default function() {
	const getFirstScrollable = (selector) => {
		let $scrollable;

		$(selector).each(function() {
			const $this = $(this);
			if($this.scrollTop() > 0) {
				$scrollable = $this;
				return false;
			} else {
				$this.scrollTop(1);
				if($this.scrollTop() > 0) {
					$scrollable = $this;
					return false;
				}
				$this.scrollTop(0);
			}
		});

		return $scrollable;
	};

	const $win = $(window),
		$doc = $(document),
		$scrollElement = getFirstScrollable('html,body'),
		mousewheel = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';

	$doc.on('click', 'a[href^="#"]', function(event) {
		event.preventDefault();
		let $target = $(this.hash), top;

		if($target.length < 1) return false;

		top = $target.offset().top;
		top = Math.min(top, $doc.height() - $win.height());

		$doc.on(mousewheel, (event) => {
			event.preventDefault();
		});

		$scrollElement.stop().animate({scrollTop: top}, 300, 'swing', () => {
			$doc.off(mousewheel);
		});
		return false;
	});
}