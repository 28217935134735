import $ from 'jquery'

let trigger = trigger || {};
trigger = {
    accordion: $('[data-trigger-accordion]')
};

let target = target || {};
target = {
    accordion: $('[data-target-accordion]')
};

export default function() {
    if(!trigger.accordion.length) return;
    trigger.accordion.on('click',function() {
        $(this).toggleClass('is-active');
        $(this).next(target.accordion).stop(false,false).slideToggle(300,'swing');
    });
}