import $ from 'jquery'

let trigger=trigger||{};
trigger={
    tab: $('[data-trigger-tab]')
};

let target=target||{};
target={
    tab: $('[data-target-tab]')
};

export default function () {
    if (!trigger.tab.length) return;
    trigger.tab.on('click',function () {
        if (!$(this).hasClass('is-active')) {
            if (!$(this).hasClass('tabArea__item__btn--sp')) {
                let tabNum=$(this).attr('data-trigger-tab');
                let tabSelect=$('[data-target-tab="'+tabNum+'"]');
                $(this).parent().nextAll('.tabArea__item').removeClass('is-active');
                $(this).parent().children('.tabArea a').removeClass('is-active');
                $(this).toggleClass('is-active')
                tabSelect.toggleClass('is-active');
            } else {
                $(this).next('.tabArea__item__txt').slideToggle().addClass('is-active');
                $(this).addClass('is-active');
            }
        } else {
            if ($(this).hasClass('tabArea__item__btn--sp')) {
                $(this).next('.tabArea__item__txt').slideToggle().removeClass('is-active');
                $(this).removeClass('is-active');
            }
        }
    });
}