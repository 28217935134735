import $ from 'jquery'
import 'slick-carousel'

$('.slideBox').slick({
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: true,
    arrows: true,
    centerPadding: false,
    variableWidth: true,
    settings: "unslick",
    prevArrow: '<div class="slide-arrow prev-arrow c-icon--arrow"></div>',
    nextArrow: '<div class="slide-arrow next-arrow c-icon--arrow"></div>',
    responsive: [
        {
            breakpoint: 768, //767px以下のサイズに適用
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '0',
                variableWidth: false,
            }
        }
    ]
});